<template>
  <div>
    <v-layout pb-6 pr-6 pl-6 class="mainfont" wrap justify-center>
      <v-flex xs12 pt-4>
        <v-layout wrap justify-center>
          <v-snackbar v-model="showSnackBar" color="black">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
            spinner="spinner" />
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 lg4 pt-8 text-left>
            <span style="font-size: 25px; font-weight: bolder">PENDING APPLICATIONS
            </span>
          </v-flex>
          <v-flex xs12 lg4 pt-3 pr-lg-4 pl-lg4>
            <!-- <v-form @submit.prevent="validateInput">
              <v-autocomplete clearable dense style="border-radius: 0%" outlined label="District" color="black"
                :items="district" v-model="districtid" item-text="officeName" item-value="_id" item-color="#FF1313"
                hide-details class="custom-autocomplete mainfont">
                <template v-slot:append>
                  <v-icon color="black">mdi-chevron-down</v-icon>
                </template>
              </v-autocomplete>
            </v-form> -->
          </v-flex>
          <v-flex xs12 lg4 pt-3 pr-lg-4 pl-lg4>
            <!-- <v-form @submit.prevent="validateInput">
              <v-autocomplete clearable dense style="border-radius: 0%" outlined label="Camps" color="black"
                :items="camps" v-model="newId" item-text="campName" item-value="_id" item-color="#FF1313" hide-details
                class="custom-autocomplete mainfont" :disabled="!districtid" @change="campSelected">
                <template v-slot:append>
                  <v-icon color="black">mdi-chevron-down</v-icon>
                </template>
              </v-autocomplete>

            </v-form> -->
          </v-flex>

          <!-- <v-flex xl3 lg2 md3 pt-6 pr-4 sm3 xs12 align-self-center>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  dense
                  outlined
                  readonly
                  v-model="fromDate"
                  v-bind="attrs"
                  background-color="white"
                  v-on="on"
                  @click:clear="from = null"
                  style="color: black"
                >
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span
                      class="custom-label-text"
                      style="color: black; font-size: 14px"
                    >
                      From Date</span
                    >
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                color="#13736f"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xl3 lg2 md3 pt-6 pr-1 sm3 xs12 align-self-center>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              max-width="290"
              elevation="0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  label="To Date"
                  readonly
                  dense
                  background-color="white"
                  color="#13736f"
                  outlined
                  v-model="toDate"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="from = null"
                >
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span
                      class="custom-label-text"
                      style="color: black; font-size: 14px"
                    >
                      To Date</span
                    >
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                color="#13736f"
                @change="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex> -->
        </v-layout>
      </v-flex>
      <v-flex xs12 pt-4>
        <!-- <v-layout wrap justify-center>
    <v-flex v-for="(district, index) in keralaDistricts" :key="index" xs12 sm12 md12 lg12>
      <router-link :to="{ name: 'PendingApplicationDivision', query: { districtName: district } }" tag="div">
        <v-card class="mainbg">
          <v-card-title style="color: white;">{{ district }}</v-card-title>
        </v-card>&nbsp;
      </router-link>
    </v-flex>
  </v-layout> -->
        <v-layout wrap justify-center>
          <v-flex v-for="(district, index) in keralaDistricts" :key="index" xs12 sm12 md12 lg12>
            <router-link :to="getEncryptedLink(district)" tag="div">
              <v-card class="mainbg">
                <v-card-title style="color: white;">{{ district }}</v-card-title>
              </v-card>&nbsp;
            </router-link>
          </v-flex>
        </v-layout>

      </v-flex>


    </v-layout>
    <v-layout wrap>
      <!-- <v-flex text-center xs12 v-if="list.length < 1">
        <span>No Pending Applications !</span>
      </v-flex> -->
    </v-layout>
    <v-layout wrap v-if="list.campid">
      <!-- <v-flex xs12 v-if="list.length > 0">
        <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
          v-model="currentPage" color="#283e51"></v-pagination>
      </v-flex> -->
    </v-layout>
    <v-layout wrap v-if="districtSelected && !newId">
      <!-- <v-flex xs12 text-center>
        <span>Please select a camp to view applications.</span>
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {

      itemArray2: ["Vegeterian", "Non-vegetarian"],
      keralaDistricts: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod",
      ],
      // cdate: moment().format("YYYY-MM-DD"),
      curIdToDelete: null,
      name: null,
      editdialog: false,
      barlist: [],
      edit: [],
      userProfileTabs: null,
      subtab: null,
      appLoading: false,
      page: 1,
      keyArray: [],
      districtSelected: false,
      district: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod",
      ],
      camps: [],

      pages: 0,
      dialog1: false,
      preview: null,
      tab: null,
      barlist1: [],
      dialog3: false,
      deletedialog: false,
      deletedialogreturn: false,

      minAmount: null,
      password: null,
      categoryarray: [],
      districtid: null,
      campid: null,
      newId: null,
      selectedUsername: null,
      usernames: [],
      reason: null,
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      currentpage: 1,
      menu1: false,
      cdate: null,
      menu2: false,
      menu3: false,
      menu4: false,
      fromDate: new Date().toISOString().substr(0, 10),

      toDate: null,
      fromDate2: null,
      toDate2: null,
      msg: null,
      currentPage: 1,
      showSnackBar: false,
      info: [],
      dialog2: false,
      viewdialog: false,
    };
  },

  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    fromDate() {
      this.getList();
    },
    toDate() {
      this.getList();
    },
    districtid() {
      this.districtSelected = !!this.districtid;
      this.getList();
    }
  },
  computed: {
    guestPhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    formattedDate() {
      const date = new Date(this.edit.selectedDate);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },

  methods: {
    getEncryptedLink(district) {
    const encryptedDistrict = CryptoJS.AES.encrypt(district, 'encryptionSecret').toString();
    return { name: 'PendingApplicationDivision', query: { districtName: encryptedDistrict } };
  },
    campSelected() {
      if (!this.newId) {
        this.$refs.snackbar.showSnackbar("Please select a camp to proceed.");
        return;
      }
      else {
        axios({
          method: "post",
          url: "/camp/application",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            campid: this.newId,
            page: this.currentPage,
            fromDate: this.fromDate,
            limit: 10,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.list = response.data.data.map((item) => {
                const date = new Date(item.selectedDate);
                item.selectedDate = date.toLocaleDateString();
                item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
                return item;
              });
              this.totalData = response.data.totalLength;
              this.selectedDatesArray = this.list.map((item) => item.selectedDate);
              this.pages = Math.ceil(this.totalData / response.data.limit);
              this.appLoading = false;
            }
            else {
              this.appLoading = false
            }

          })
          .catch((err) => {
            this.ServerError = true;
            console.error(err);
          });
      }
    },
    getUsers(appid) {
      axios({
        method: "post",
        url: "/dfo/forward/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: appid,
        },
      })
        .then((response) => {
          this.usernames = [
            // {
            //   name: `${response.data.applicant.username} (User)`,
            //   role: "User",
            //   userId: response.data.applicant._id,
            // },
            {
              name: `${response.data.superintendent.username} (Division Superintendent)`,
              role: "Superintendent",
              userId: response.data.superintendent._id,
            },
            {
              name: `${response.data.user.username} (User)`,
              role: "User",
              userId: response.data.user._id,
            },
          ];
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    forwardToUsers(_id) {
      this.getUsers(_id);
      this.appids = _id;
    },
    forwardToUsers2(appids) {
      this.forward(appids);
    },
    forward(appids) {
      if (!this.selectedUsername) {
        this.$refs.snackbar.showSnackbar("Please select a user to forward to.");
        return;
      }

      const selectedUser = this.usernames.find(
        (user) => user.name === this.selectedUsername
      );

      if (!selectedUser) {
        return;
      }

      axios({
        method: "post",
        url: "/forward/",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: appids,
          forwardto: selectedUser.userId,
          reason: this.reason,
        },
      })
        .then((response) => {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          window.location.reload();
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    setCurIdToDelete(id) {
      this.curIdToDelete = id;
    },
    deleteItem() {
      const idToDelete = this.curIdToDelete;

      if (!idToDelete) {
        return;
      }

      axios({
        url: "/cancel/application",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: idToDelete,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    // getList() {
    //   this.appLoading = true;
    //   axios({
    //     method: "POST",
    //     url: "/forwarded/apps",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       status: this.status,
    //       district: this.districtid,
    //       categoryId: this.categoryId,
    //       page: this.currentPage,
    //       fromDate: this.fromDate,
    //       toDate: this.toDate,
    //       limit: 10,
    //     },
    //   })
    //     .then((response) => {
    //       this.list = response.data.data.map((item) => {
    //         const date = new Date(item.selectedDate);
    //         item.selectedDate = date.toLocaleDateString();
    //         item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
    //         return item;
    //       });
    //       this.totalData = response.data.totalLength;
    //       this.selectedDatesArray = this.list.map((item) => item.selectedDate);
    //       this.pages = Math.ceil(this.totalData / response.data.limit);
    //       this.appLoading = false;
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    // getList() {
    //   this.appLoading = true;
    //   axios({
    //     method: "POST",
    //     url: "/district/camps",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       status: this.status,
    //       district: this.districtid,
    //       categoryId: this.categoryId,
    //       page: this.currentPage,
    //       fromDate: this.fromDate,
    //       toDate: this.toDate,
    //       limit: 20,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         this.list = response.data.data.map((item) => {
    //           const date = new Date(item.selectedDate);
    //           item.selectedDate = date.toLocaleDateString();
    //           item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
    //           return item;
    //         });
    //         // if(this.list)
    //         // {
    //         //   this.camps =this.list.map((camp) => camp.campName);
    //         // }
    //         if (this.list) {
    //           this.camps = this.list.map((camp) => ({
    //             _id: camp._id,
    //             campName: camp.campName,
    //           }));
    //         }
    //         this.totalData = response.data.totalLength;
    //         this.selectedDatesArray = this.list.map((item) => item.selectedDate);
    //         this.pages = Math.ceil(this.totalData / response.data.limit);
    //         this.appLoading = false;
    //       }
    //       else {
    //         this.appLoading = false
    //       }

    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
  
<style>
.subheading {
  font-family: mainfont;
  color: rgb(13, 154, 69);
  text-transform: none;
  font-size: 17px;
}

.heading {
  font-family: mainfont;
  color: rgb(229, 111, 8);
  text-transform: none;
  font-size: 25px;
}

.mainbg {
  background-image: linear-gradient(269.6deg, #29807c -31.66%, #29807c);
}
</style>